<template>
  <div class="news container">
    <el-tabs tab-position="left" v-model="params.type">
      <el-tab-pane label="行业资讯" name="1">
        <div class="box">
          <div
            class="one"
            v-for="item in list"
            :key="item.newsId"
            @click="handleClick(item.newsId)"
          >
            <div class="cover">
              <img :src="item.mainImg" alt="" />
            </div>
            <div class="info">
              <p class="title">{{ item.title }}</p>
              <p class="author">
                <img :src="item.sourceIcon" alt="" />{{ item.source }}
              </p>
              <p class="time">{{ item.createTime }}</p>
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="公司资讯" name="2"
        ><div class="box">
          <div
            class="one"
            v-for="item in list"
            :key="item.newsId"
            @click="handleClick(item.newsId)"
          >
            <div class="cover">
              <img :src="item.mainImg" alt="" />
            </div>
            <div class="info">
              <p class="title">{{ item.title }}</p>
              <p class="author">{{ item.source }}</p>
              <p class="time">{{ item.createTime }}</p>
            </div>
          </div>
        </div></el-tab-pane
      >
    </el-tabs>
    <div class="foot_page">
      <el-pagination
        background
        layout="prev, pager, next, jumper"
        :page-size="params.pageSize"
        :current-page.sync="params.pageNum"
        :total="total"
        @current-change="getList"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import { newsList } from "@/api/news";
export default {
  data() {
    return {
      params: {
        pageNum: 1,
        pageSize: 12,
        type: "1",
      },
      total: 0,
      list: [],
    };
  },
  created() {
    this.params.type = this.$route.query.type || "1";
    this.getList();
  },
  watch: {
    "params.type": {
      handler() {
        this.params.pageNum = 1;
        this.getList();
      },
      deep: true,
    },
  },
  methods: {
    getList() {
      this.$loading.show();
      newsList(this.params).then((res) => {
        this.$loading.hide();
        if (res.code == 200) {
          this.list = res.rows;
          this.total = res.total;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    handleClick(newsId) {
      const { href } = this.$router.resolve({
        path: "/newsDetail",
        query: { newsId },
      });
      window.open(href, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.news {
  position: relative;
  padding: 20px 0 40px;
  min-height: calc(100vh - 66px - 200px);
  .box {
    padding-bottom: 20px;
    text-align: left;
    .one {
      display: inline-block;
      margin: 10px;
      width: 253px;
      // height: 280px;
      vertical-align: top;
      cursor: pointer;
      &:hover {
        .cover img {
          transform: scale(1.2, 1.2);
        }
      }
      .cover {
        width: 100%;
        overflow: hidden;
        img {
          width: 100%;
          max-height: 110px;
          transition: all 0.6s ease;
        }
      }
      .info {
        padding: 12px;
        background-color: #f4f4f4;
        .title {
          margin-bottom: 30px;
          min-height: 42px;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .author {
          margin-bottom: 15px;
          min-height: 43px;
          img {
            margin-right: 10px;
            width: 24px;
            height: 24px;
            border-radius: 50%;
          }
        }
        .time {
          color: #bfbfbf;
        }
      }
    }
  }
  .foot_page {
    position: absolute;
    right: 0;
    bottom: 20px;
    // margin-top: 15px;
    // text-align: right;
    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #0d79ff;
    }
  }
}
</style>