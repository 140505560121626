import Http from '@/utils/http'
const baseUrl = '/app/study'


/**
 * 获取新闻列表
 * @param 
 * method:get
 * path:'/app/study/news'
*/

export const newsList = params => Http.get(`${baseUrl}/news`, { params })

/**
 * 获取新闻详情
 * @param 
 * method:get
 * path:'/app/study/news/{newsId}'
*/

export const newsDetail = newsId => Http.get(`${baseUrl}/news/${newsId}`)
